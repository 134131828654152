<template>
  <div class="ForgetPage">
    <div class="Forget">
      <h1>忘记密码</h1>
      <el-form
        ref="ForgetPasswordForm"
        :model="ForgetPasswordForm"
        label-width="90px"
        :rules="ForgetPasswordRules"
      >
        <el-form-item label="动态验证码" prop="code">
          <div class="formLabel">
            <el-input
              v-model="ForgetPasswordForm.code"
              placeholder="动态验证码"
              autocomplete="off"
            ></el-input>
            <div @click="getYzm">
              <Yzm @strchange="strchange" :identifyCode="code"></Yzm>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="手机号" prop="Phone">
          <el-input
            :disabled="inputDisabled"
            v-model="ForgetPasswordForm.Phone"
            placeholder="手机号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="NewPwd">
          <el-input
            :disabled="inputDisabled"
            v-model="ForgetPasswordForm.NewPwd"
            placeholder="新密码"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="ConPwd">
          <el-input
            :disabled="inputDisabled"
            v-model="ForgetPasswordForm.ConPwd"
            placeholder="重复密码"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机验证码" prop="SmsCode">
          <div class="formLabel">
            <el-input
              :disabled="inputDisabled"
              v-model="ForgetPasswordForm.SmsCode"
              placeholder="手机验证码"
              autocomplete="off"
            ></el-input>

            <el-button
              type="primary"
              style="margin-left: 5px"
              :disabled="disabled || inputDisabled"
              @click="sendCode"
              >{{
                !disabled ? "发送验证码" : oneMinute + "重新发送"
              }}</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      <el-button :disabled="inputDisabled" type="primary" @click="ForgetConfirm" class="confirmBtn"
        >确 定</el-button
      >
    </div>
  </div>
</template>

<script>
const regExp = new RegExp("^1[3456789]\\d{9}$"); // 手机号正则
import Yzm from "@/views/Register/list.vue";
export default {
  components: {
    Yzm,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ForgetPasswordForm.NewPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      code: "",
      keyCode: "",
      timer: null,
      oneMinute: 60,
      disabled: false,
      ForgetPasswordForm: { code: "" },
      ForgetPasswordRules: {
        Phone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入手机号"));
              } else if (!regExp.test(value)) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        NewPwd: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        ConPwd: [{ required: true, validator: validatePass, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getYzm();
  },
  computed: {
    inputDisabled() {
      return this.keyCode.toUpperCase() ==
        this.ForgetPasswordForm.code.toUpperCase()
        ? false
        : true;
    },
  },
  methods: {
    strchange(e) {
      this.keyCode = e;
    },
    getYzm() {
      var str =
        "qwertyuioplkjhgfdsazxcvbnm1234567890QWERTYUIOPLKJHGFDSAZXCVBNM";
      var Yzm = "";
      for (var i = 0; i <= 3; i++) {
        var item = parseInt(Math.random() * (str.length - 1 + 0 + 1) - 0);
        Yzm += str[item];
      }
      this.code = Yzm;
    },
    // 忘记密码
    ForgetConfirm() {
      this.$refs["ForgetPasswordForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (!this.ForgetPasswordForm.SmsCode) {
          return this.$message.error("请填写验证码");
        }
        this.$http
          .post(
            `/Base_Manage/Base_User/SubResetUserPwd?Phone=${this.ForgetPasswordForm.Phone}&ConPwd=${this.ForgetPasswordForm.ConPwd}&SmsCode=${this.ForgetPasswordForm.SmsCode}`
          )
          .then((res) => {
            if (res.Success) {
              this.$message.success("密码重置成功,请登录");
              this.ForgetVisible = false;
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    sendCode() {
      this.$refs["ForgetPasswordForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        clearInterval(this.timer);
        this.disabled = true;
        this.timer = setInterval(() => {
          this.oneMinute--;
          if (this.oneMinute <= 0) {
            clearInterval(this.timer);
            this.oneMinute = 60;
            this.disabled = false;
          }
        }, 1000);
        this.$http
          .post("/Base_Manage/Base_User/ResetUserPwd", this.ForgetPasswordForm)
          .then((res) => {
            if (res.Success) {
              this.$message.success("验证码已经发送至您的手机,请注意查收");
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scope>
.ForgetPage {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.Forget {
  width: 520px;
  position: absolute;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  height: 600px;
  .formLabel {
    display: flex;
  }
  .confirmBtn {
    width: 200px;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    bottom: 20px;
    left: 160px;
  }
}
</style>